require('jssocials');

/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(document).ready(function() {

    'use strict';

    $('#shareRoundIcons').jsSocials({
        shareIn: 'popup',
        showLabel: false,
        showCount: false,
        shares: [
            {
                share: 'twitter',
                logo: '/images/icons/twitter.svg',
            },
            {
                share: 'facebook',
                logo: '/images/icons/facebook.svg',
            },
        ],
    });
});
