window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/*
 |--------------------------------------------------------------------------
 | Twitter Bootstrap
 |--------------------------------------------------------------------------
 |
 | Here we can require only the specific twitter bootstrap components that
 | our application is going to use, this way we don't include the whole
 | library and we reduce the file size of our final javascript file.
 |
 */

require('bootstrap');

/*
 |--------------------------------------------------------------------------
 | SweetAlert
 |--------------------------------------------------------------------------
 |
 | SweetAlert is a beautiful replacement for JavaScript's alerts that makes
 | popup messages easy and pretty and looks great no matter if you're on
 | a desktop computer, mobile or tablet. It's even highly customizable
 |
 */

window.swal = require('sweetalert2');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
