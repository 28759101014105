/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(function () {
    'use strict';

    /**
     * Display the tooltip for elements with data-toggle="tooltip" attribute.
     * @link http://getbootstrap.com/javascript/#tooltips
     */
    $('[data-toggle="tooltip"]').tooltip({
        sanitize: false,
    });
});
